
import React from 'react'
import eye from '../Img/eye.jpg';
import def from '../Img/default Image.jpg'
function FourthComp() {
    return (
        <>
        <div className='mt-[10px]'>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className='relative'>
                <img src={def} alt='image' />
                <div className='absolute bottom-0 bg-black opacity-70 text-white p-[10px] w-full'>
                    <h1 className='text-[40px] font-bold'>Bingo! Tedhe Medhe Releases Pujo Special Song ‘Rockanjali’</h1>
                </div>
            </div>
            <div class="grid grid-rows-2 gap-4 sm:flex sm:flex-col">
                <div>
                    <div className='relative'>
                        <img className='w-full' style={{ height: '190px' }} src={eye} alt='image' />
                        <div className='absolute bottom-0 bg-black opacity-70 text-white w-full'>
                            <h1 className='text-[28px] font-bold'>Bingo! Tedhe Medhe Releases Pujo Special Song ‘Rockanjali’</h1>
                        </div>
                    </div>
                </div>
                <div>
                <div className='relative'>
                        <img className='w-full' style={{ height: '190px'}} src={eye} alt='image' />
                        <div className='absolute bottom-0 bg-black opacity-70 text-white w-full'>
                            <h1 className='text-[28px] font-bold'>Bingo! Tedhe Medhe Releases Pujo Special Song ‘Rockanjali’</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </>
    )
}

export default FourthComp
