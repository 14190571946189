import './App.css';
import FirstComp from './Components/Homepage/FirstComp';
import SecondCompNavbar from './Components/Homepage/SecondComp(Navbar)';
import SearchComp from './Components/Homepage/SearchComp';
import ThirdComp from './Components/Homepage/ThirdComp';
import DataContext from './Components/Homepage/DataContext';
// import { BrowserRouter } from 'react-router-dom';
import Footer from './Components/Homepage/Footer';
import EndFooter from './Components/Homepage/EndFooter';
function App() {
  return (
    <div >
      <FirstComp />
      <SearchComp />
      <SecondCompNavbar />
      <ThirdComp />
      <DataContext/>
      <Footer/>
      <EndFooter/>
    </div>
  );
}

export default App;
