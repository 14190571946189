import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Img/FooterLogo.png'
function Footer() {
    return (

        <div class="grid grid-cols-8 gap-4 bg-black text-white pt-[20px] pb-[10px] ">
            <div class="col-span-12 md:col-span-4">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img style={{ height: '115px' }} src={Logo}></img>
                </div>
            </div>
            <div class="col-span-12 md:col-span-4 ">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} class="flex-1 p-4 ">
                    <ul className='mr-[100px]'>
                        <p className='hover:underline'>UPCOMING EVENT</p>
                        <p className='hover:underline'>ADVERTISMENT</p>
                        <p className='hover:underline'>EVENT</p>
                    </ul>
                    <ul>
                        <p className='hover:underline'>MARKETING</p>
                        <p className='hover:underline'>MEDIA</p>
                        <p className='hover:underline'>AUTHOR</p>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Footer



