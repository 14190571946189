import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import FifthCom from './FifthCom';
import FourthComp from './FourthComp';
function DataContext() {

  const [value, setValue] = useState([]);
  const [load, setLoad] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch('https://brandvibez.com/wp-json/wp/v2/posts')
      .then(response => response.json())
      .then(val => {
        setValue(val)
        setLoad(false);
      })
      .catch(error => {
        setLoad(false);
        setError(error)
      })
  }, [])


  return (
    <>
      <div>
        {load ? (
          <h1>LOADING.....</h1>
        ) : error ? (
          <h1>Error</h1>
        ) : (
          <div>
            <FourthComp value={value.slice(0, 3)}></FourthComp>
            <FifthCom value={value.slice(3)}></FifthCom>
          </div>
        )}
      </div>
    </>
  )
}

export default DataContext