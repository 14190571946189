import React from 'react'

function FifthCom({ value }) {
    return (
        <>
            <div>
                <div className="container mx-auto p-4 pt-6 md:p-6 lg:p-12">
                    <h2 className="text-2xl font-bold mb-4">Top Headlines</h2>
                    <div className="flex flex-wrap -mx-4">
                        {value.map((item) => (
                            <div key={item.id} className="w-full md:w-1/2 xl:w-1/4 p-4">
                                <div className="max-w-sm mx-auto bg-white rounded-lg shadow-md overflow-hidden">
                                    <img src={item.featured_media_src_url} className="card-img-top transition duration-500" alt="..." />
                                    <div className="p-4">
                                        <p className="text-red-600 text-sm font-semibold">UPCOMING EVENTS</p>
                                        <p className="text-red-600 text-sm font-semibold">{ }</p>
                                        <h2 className="text-gray-800 text-xl font-bold mt-2" dangerouslySetInnerHTML={{ __html: item.title.rendered.slice(0, 70) }}></h2>
                                        <p className="text-gray-500 text-sm mt-2">{item.date}</p>
                                        <p className="text-gray-600 text-sm mt-4" dangerouslySetInnerHTML={{ __html: item.excerpt.rendered.slice(0, 200) }}></p>
                                        <p className="text-gray-600 text-sm mt-4">Author - Kiran Giradkar</p>
                                        <button className="mt-4 bg-red-600 text-white px-4 py-2 rounded"><a href={item.link} target="_blank" rel="noopener noreferrer">Read More..</a></button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default FifthCom